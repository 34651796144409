// Import the Preference class
import Preference from "./Preference";

// Define the Role class
class Role {
  // Empty constructor for the Role class
  constructor() {}
  // Method to get roles from the preference data
  getRoles() {
    // Create a new instance of the Preference class
    let preference = new Preference();
    // Parse the access permission data from the preference
    let permissionsArray = JSON.parse(preference.getData(Preference.KEY_ACCESS_PERMISSION));
    // Return an object with roles property containing the parsed permissions array
    return {
      roles: permissionsArray
    };
  }

  // Function to check access based on role_id, resource, and action
  checkAccess(role_id, resource, action) {
    // Retrieve the roles array from the result of getRoles method
    let roles = this.getRoles().roles;

    // Find the role in the roles array based on role_id
    let role = roles.find((role) => role.role_id === role_id);

    // Check if the role and specific access properties exist
    if (role && role.access_properties[resource] && role.access_properties[resource][action] !== undefined) {
      // Return the access value if it exists
      return role.access_properties[resource][action];
    }

    // Return false if the role or access property doesn't exist
    console.log("access property doesn't exist");
    return false;
  }
}

// Export the Role class
export default Role;
