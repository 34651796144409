<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import HttpRequest from "@/http/HttpRequest";
import Utils from "@/helpers/Utils";
import Preference from "@/data/Preference";
import Role from "@/data/Roles";
import Dropdown from '@/components/Dropdown';

let roleChecker = new Role();

let httpRequest = new HttpRequest();
let preference = new Preference();

export default {
  data() {
    return {
      title: "Apps",
      items: [
        {
          text: "WORKANY",
          to: "/",
        },
        {
          text: "Apps",
          active: true,
        },
      ],
      role: preference.getData(Preference.KEY_ROLE_ID),
      isLoading: false,
      isLoadingMembers: false,
      projectListSelectOption: [],
      projectIdList: [],
      memberListSelectOption: [],
      selectedMemberId: null,
      date: null,
      selectedMemberNameInit: preference.getData(Preference.KEY_NAME),
      datePickerPlaceholder: "Today",
      activitiesData: [],
      all: {
        id: null,
        name: "All",
      },
      me: {
        id: null,
        name: "Me",
      },
      appsAndURLsTableData: [],
      selectedProjectId: null,
      fromDate: Utils.getToday(),
      toDate: Utils.getToday(),
      isOwnerOfTheSelectedProject: false,
      appUsesTableFields: [
        { key: "serial_number", sortable: false, label: "SN" },
        { key: "app_name", sortable: true, label: "App" },
        { key: "active_sec", sortable: true, label: "Spent" },
        { key: "occurrence", sortable: true, label: "Count" },
        //{key: "url_data", sortable: false, label: "URLs"}
      ],
    };
  },

  components: {
    Layout,
    PageHeader,
    Dropdown
  },

  methods: {

    validateSelection(selection) {
      if (selection) {
        this.selectedMemberId = selection.id;

      }
    },
    isRoleAccess(role_id, resource, action) {
      return roleChecker.checkAccess(role_id.toString(), resource, action);
    },
    getDashboardWindowLogList() {
      let _this = this;

      let fromDate = Utils.formatDate(this.fromDate);
      let toDate = Utils.formatDate(this.toDate);
      let timezone = Utils.getMyTimezone();
      _this.isLoading = true;
      httpRequest
        .getDashboardWindowLog(
          fromDate,
          toDate,
          _this.selectedProjectId,
          _this.selectedMemberId,
          timezone
        )
        .then(function (response) {
          _this.isLoading = false;
          if (response.success && response.data.length > 0) {
            _this.appsAndURLsTableData = [];

            let appsAndURLsData = response.data[0];
            let date = appsAndURLsData.date;
            let day_data = appsAndURLsData.day_data;
            let appUrlData;
            day_data.forEach(function (dayData, i) {
              let urlData = [];
              dayData.url_data.split(",").forEach(function (url) {
                let host = _this.getHostnameFromUrl(url);
                if (host != null) {
                  let urlContent = {
                    siteName: host,
                    url: url,
                  };
                  urlData.push(urlContent);
                }
              });

              appUrlData = {
                serial_number: i + 1,
                date: date,
                app_name: dayData.app_name,
                url_data: urlData,
                hour_slot: dayData.hour_slot,
                occurrence: dayData.occurrence,
                active_sec: Utils.secToTime(dayData.active_sec),
                expand: false,
              };
              _this.appsAndURLsTableData.push(appUrlData);
            });
          } else {
            _this.appsAndURLsTableData = [];
          }
        });
    },
    getProjectList() {
      let _this = this;
      _this.isLoading = true;
      let accessPermission = this.isRoleAccess(this.role, "Project", "read");

      return httpRequest.getProjectList(accessPermission).then(function (response) {
        _this.isLoading = false;
        if (response.success) {
          response.data.forEach(function (projectData) {
            if (projectData.project.project_status == 1) {
              let project = {
                id: projectData.project.project_id,
                name: projectData.project.project_name,
              };
              _this.projectListSelectOption.push(project);
              if (_this.isRoleAccess(projectData.role_id, "Project", "read")) {
                _this.projectIdList.push(projectData.project.project_id);
              }
            }
          });
        }
      });
    },
    getProjectMemberList() {
      let _this = this;
      _this.memberListSelectOption = [];
      if (_this.selectedProjectId != null) {
        _this.isLoadingMembers = true;
        httpRequest.getProjectMembers(_this.selectedProjectId).then(function (response) {
          _this.isLoadingMembers = false;
          if (response.success) {
            let myUserId = preference.getData(Preference.KEY_USER_ID);
            _this.isOwnerOfTheSelectedProject = false;
            response.data.forEach(function (member) {
              let myRoleId = -1;
              if (member.user.user_id === myUserId) {
                _this.selectedMemberId = member.user.user_id;
                myRoleId = member.role_id;
                _this.isOwnerOfTheSelectedProject = _this.isRoleAccess(
                  myRoleId,
                  "Member",
                  "read"
                );
              }

              let user = {
                id: member.user.user_id,
                name: member.user.name,
              };
              _this.memberListSelectOption.push(user);
            });

            if (
              _this.isOwnerOfTheSelectedProject == false &&
              _this.isRoleAccess(_this.role, "Member", "read") == false
            ) {
              _this.memberListSelectOption = [];
              let user = {
                id: _this.selectedMemberId,
                name: preference.getData(Preference.KEY_NAME),
              };
              _this.memberListSelectOption.push(user);
            }
          }
        });
      } else {
        _this.getAllMembersBasedOnProjectList();
      }
    },
    getAllMembersBasedOnProjectList() {
      let _this = this;
      _this.memberListSelectOption = [];
      if (_this.projectIdList.length > 0) {
        _this.isLoadingMembers = true;
        httpRequest.getProjectMembers(_this.projectIdList).then(function (response) {
          _this.isLoadingMembers = false;
          if (response.success) {
            _this.isOwnerOfTheSelectedProject = false;
            response.data.forEach(function (member) {
              if (member.user !== null) {
                let user = {
                  id: member.user.user_id,
                  name: member.user.name,
                };
                _this.memberListSelectOption.push(user);
              }
            });
            // remove duplicate if exist
            if (_this.memberListSelectOption.length > 0) {
              const key = "id";
              _this.memberListSelectOption = [
                ...new Map(
                  _this.memberListSelectOption.map((item) => [item[key], item])
                ).values(),
              ];
            } else {
              _this.selectedMemberId = preference.getData(Preference.KEY_USER_ID);
              _this.me.id = _this.selectedMemberId;
              _this.me.name = preference.getData(Preference.KEY_NAME);
              _this.memberListSelectOption.push(this.me);
            }
          }
        });
      } else {
        _this.selectedMemberId = preference.getData(Preference.KEY_USER_ID);
        _this.me.id = _this.selectedMemberId;
        _this.me.name = preference.getData(Preference.KEY_NAME);
        _this.memberListSelectOption.push(this.me);
      }
    },
    loadSelectedData() {
      let cardBody = document.querySelector('.card-body');
      cardBody.classList.remove('card-body-height');
      this.getDashboardWindowLogList();
    },
    loadInitialData() {
      this.fromDate = Utils.formatDate(this.fromDate).replaceAll("/", "-");
      this.toDate = Utils.formatDate(this.toDate).replaceAll("/", "-");
      this.projectListSelectOption.push(this.all);
      if (this.selectedMemberId == null) {
        this.selectedMemberId = preference.getData(Preference.KEY_USER_ID);
        this.me.id = this.selectedMemberId;
        this.me.name = preference.getData(Preference.KEY_NAME);
      }
      this.memberListSelectOption.push(this.me);
      this.getDashboardWindowLogList();
    },
    expandUrls(data) {
      data.expand = true;
    },
    getHostnameFromUrl(string) {
      if (string) {
        try {
          return new URL(string).hostname;
        } catch (error) {
          return string;
        }
      }
      return null;
    },
    printData() {
      if (this.appsAndURLsTableData.length > 0) {
        window.print();
      }
    },

    containsSubtexts(sentence) {
      const subtextsToSearch = ["Google Chrome", "chrome", "firefox", "Mozilla Firefox"];

      // Check if any of the subtexts is present in the sentence
      return subtextsToSearch.some((subtext) => sentence.includes(subtext));
    },
  },
  async created() {
    await this.getProjectList();
    await this.loadInitialData();
    await this.getAllMembersBasedOnProjectList();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col mb-4">
        <div class="card">
          <div class="card-body card-body-height">
            <div class="row report-form-row">
              <div class="col-sm-6 col-md-6 col-xl-3">
                <label class="report-form-label">From</label>
                <template>
                  <div>
                    <b-input-group>
                      <b-input-group-prepend>
                        <!-- Icon or content to prepend -->
                        <span class="input-group-text">
                          <i class="ri-calendar-line customs-text-color"></i>
                        </span>
                      </b-input-group-prepend>

                      <b-form-datepicker :date-format-options="{
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      weekday: 'short',
    }" id="from-datepicker" v-model="fromDate"></b-form-datepicker>
                    </b-input-group>
                  </div>
                </template>
              </div>
              <div class="col-sm-6 col-md-6 col-xl-3">
                <label class="report-form-label">To</label>
                <template>
                  <div>
                    <b-input-group>
                      <b-input-group-prepend>
                        <span class="input-group-text">
                          <i class="ri-calendar-line customs-text-color"></i>
                        </span>
                      </b-input-group-prepend>

                      <b-form-datepicker :date-format-options="{
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      weekday: 'short',
    }" id="to-datepicker" v-model="toDate"></b-form-datepicker>
                    </b-input-group>
                  </div>
                </template>
              </div>
              <div class="col-sm-6 col-md-2">
                <label class="report-form-label">Project</label>
                <template>
                  <div>
                    <b-input-group>
                      <b-input-group-prepend>
                        <span class="input-group-text">
                          <i class="ri-folder-line customs-text-color"></i>
                        </span>
                      </b-input-group-prepend>
                      <b-form-select :options="projectListSelectOption" v-model="selectedProjectId" value-field="id"
                        text-field="name" v-on:change="getProjectMemberList"></b-form-select>
                    </b-input-group>
                  </div>
                </template>
              </div>
              <div class="col-sm-6 col-md-2">
                <label class="report-form-label">Member</label>
                <template>
                  <div>
                    <b-input-group>
                      <b-input-group-prepend>
                        <span class="input-group-text">
                          <i class="ri-user-3-line customs-text-color"></i>
                        </span>
                      </b-input-group-prepend>
                      <!-- 
                      <b-form-select :options="memberListSelectOption" v-model="selectedMemberId" value-field="id"
                        text-field="name" :disabled="isLoadingMembers"></b-form-select> -->
                      <Dropdown class="dropdown-width" :options="memberListSelectOption" :maxItem="200"
                        v-on:selected="validateSelection" :initialData="selectedMemberNameInit">

                      </Dropdown>
                    </b-input-group>
                  </div>
                </template>
              </div>
              <div class="col-sm-6 col-md-auto col-xl-2 report-search">
                <label class="report-form-label">Action</label>
                <b-button block class="customs-bg-color" variant="primary" v-on:click="loadSelectedData"><i
                    class="ri-eye-line" style="vertical-align: bottom"></i> VIEW
                </b-button>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-12">
                <div class="table-responsive">
                  <b-table ref="projectTable" show-empty :items="appsAndURLsTableData" :striped="false" responsive="sm"
                    :fields="appUsesTableFields" :bordered="true" :hover="true" :dark="false" :busy="isLoading">
                    <template v-for="field in appUsesTableFields" v-slot:[`head(${field.key})`]="{ label }">
                      <a class="" :style="{ color: '#ff7c47' }" v-bind:key="field.key">{{
      label
    }}</a>
                    </template>
                    <template #table-busy>
                      <div class="text-center">
                        <b-spinner class="align-middle"></b-spinner>
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>

            <div class="report-bottom text-right" v-if="appsAndURLsTableData.length > 0">
              <b-button variant="primary" class="customs-bg-color" v-on:click="printData">
                <i class="ri-printer-line" style="vertical-align: middle"></i> PRINT
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
@media print {
  .card {
    font-size: 1rem;
  }

  .report-form-label {
    padding-top: 15px;
    margin-bottom: -10px;
  }

  .report-form-row>div {
    max-width: 33.3333333333%;
    flex: 0 0 33.3333333333%;
  }

  .report-form-row .form-control,
  .report-form-row .form-control label,
  .report-form-row .custom-select {
    border-width: 0;
    -webkit-appearance: none;
    appearance: none;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-top: 0px;
  }

  .b-form-btn-label-control.form-control>#from-datepicker,
  .b-form-btn-label-control.form-control>#to-datepicker,
  .report-search,
  .report-bottom {
    display: none !important;
  }
}

.customs-bg-color {
  background-color: #ff7c47 !important;
  border: none;
}

.customs-text-color {
  color: #ff7c47;
}

#from-datepicker>svg {
  display: none;
}

.card-body-height {
  height: 80vh !important;
}
</style>

<style>
#to-datepicker>svg {
  display: none;
}
</style>
