<template>
  <div class="dropdown" v-if="options">

    <!-- Dropdown Input -->
    <input class="dropdown-input" :name="name" @focus="showOptions()" @blur="exit()" @keyup="keyMonitor"
      v-model="searchFilter" :disabled="disabled" :placeholder="placeholder" />

    <!-- Dropdown Menu -->
    <div class="dropdown-content" v-show="optionsShown">
      <div class="dropdown-item" @mousedown="selectOption(option)" v-for="(option, index) in filteredOptions"
        :key="index">
        {{ option.name || option.id || '-' }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  template: 'Dropdown',
  initialvalue: 'sss',
  props: {
    name: {
      type: String,
      required: false,
      default: 'dropdown',
      note: 'Input name'
    },
    options: {
      type: Array,
      required: true,
      default: function () {
        return {
          // default options
        };
      },
      note: 'Options of dropdown. An array of options with id and name',
    },
    placeholder: {
      type: String,
      required: false,

      note: 'Placeholder of dropdown'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Disable the dropdown'
    },
    maxItem: {
      type: Number,
      required: false,
      default: 20,
      note: 'Max items showing'
    },
    initialData: {
      type: String,
      required: false,
      default: '',
      note: 'Initial input data to be displayed'
    }
  },
  data() {
    return {
      selected: {},
      optionsShown: false,
      searchFilter: ''
    }
  },
  created() {
    this.searchFilter = this.initialData;
    // Emit the initial selection
    this.$emit('selected', this.selected);
    this.$emit('selected', this.selected);
  },
  computed: {
    filteredOptions() {
      const filtered = [];
      const regOption = new RegExp(this.searchFilter, 'ig');
      for (const option of this.options) {
        if (this.searchFilter.length < 1 || option.name.match(regOption)) {
          if (filtered.length < this.maxItem) filtered.push(option);
        }
      }
      return filtered;
    }
  },
  methods: {
    selectOption(option) {
      this.selected = option;
      this.optionsShown = false;
      this.searchFilter = this.selected.name;
      this.$emit('selected', this.selected);
    },
    showOptions() {
      if (!this.disabled) {
        this.searchFilter = '';
        this.optionsShown = true;
      }
    },
    exit() {
      if (!this.selected.id) {
        this.selected = {};
        this.searchFilter = '';
      } else {
        this.searchFilter = this.selected.name;
      }
      this.$emit('selected', this.selected);
      this.optionsShown = false;
    },
    // Selecting when pressing Enter
    keyMonitor: function (event) {
      if (event.key === "Enter" && this.filteredOptions[0])
        this.selectOption(this.filteredOptions[0]);
    }
  },
  watch: {
    searchFilter() {
      if (this.filteredOptions.length === 0) {
        this.selected = {};
      } else {
        this.selected = this.filteredOptions[0];
      }
      this.$emit('filter', this.searchFilter);
    }
  }
};
</script>


<style lang="scss" scoped>
.dropdown {
  position: relative;
  /* display: inline-block; */
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
  /* Adjusted to inline-block */
}

.dropdown-input {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.94rem + 2px);
  padding: 0.47rem 1.75rem 0.47rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #505d69;
  vertical-align: middle;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

dropdown-input:focus {
  border-color: #b1bbc4;
  outline: 0;
  /* box-shadow: 0 0 0 0.15rem rgba(75, 31, 229, 0.25); */
}

.dropdown-content {
  position: fixed;
  background-color: #fff;
  max-width: 248px;
  max-height: 370px;
  border: 1px solid black;
  border-top: none;
  border-radius: 0 0 0.25rem 0.25rem;
  box-shadow: 0px 8px 34px 0px rgba(0, 0, 0, 0.05);
  overflow-y: auto;
  z-index: 1000;
}

.dropdown-item {
  color: #212529;
  /* Adjusted text color */
  font-size: 13px;
  /* Adjusted font size */
  line-height: .5;
  /* Adjusted line height */
  padding: .375rem .75rem;
  /* Adjusted padding */
  text-decoration: none;
  display: block;
  cursor: pointer;

  &:hover {
    background-color: #f8f9fa;
    /* Adjusted hover background color */
    color: #16181b;
    /* Adjusted hover text color */
  }
}

.dropdown-content.show {
  display: block;
}
</style>
